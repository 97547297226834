export const Routing = {
  Initial: `/`,
  Specialities: `/specialities`,
  Profile: `/settings/profile`,
  AddSpecialities: `/specialities/add`,
  EditSpecialities: `/specialities/edit/:specialityId`,
  Professionals: `/professionals`,
  AddProfessionals: `/professions/add`,
  ViewProfessionals: `/professions/view/:professionId`,
  EditProfessionals: `/professions/edit/:professionId`,
  Establishment: `/establishments`,
  AddEstablishment: `/establishments/add`,
  EditEstablishment: `/establishments/edit/:establishmentId`,

  ManageEstablishmentHours: `/establishments/manage-hours/:establishmentId`,
  AddEstablishmentWorkingHours: `/establishments/manage-hours/add/:establishmentId`,
  EditEstablishmentWorkingHours: `/establishments/manage-hours/edit/:hoursId`,

  ManageEstablishmentHoliday: `/establishments/manage-holidays/:establishmentId`,
  AddEstablishmentHoliday: `/establishments/manage-holidays/add/:establishmentId`,
  EditEstablishmentHoliday: `/establishments/manage-holidays/edit/:holidayId`,

  ViewEstablishment: `/establishments/view/:establishmentId`,
  Departments: `/departments`,
  AddDepartment: `/departments/add`,
  EditDepartment: `/departments/edit/:departmentId`,
  ManageDepartmentHolidays: "/departments/manage-holidays/:departmentId",
  AddDepartmentHolidays: "/departments/manage-holidays/add/:departmentId",
  EditDepartmentHolidays: "/departments/manage-holidays/edit/:holidayId",
  AddDepartmentWorkingHours: `/departments/manage-hours/add/:departmentId`,
  EditDepartmentWorkingHours: `/departments/manage-hours/edit/:hoursId`,

  EstablishmentTypes: `/establishmenttypes`,
  EstablishmentSubTypes: `/establishment-sub-types`,

  ProfessionsTypes: `/professionstypes`,
  Banners: `/banners`,
  Nationalities: `/nationalities`,

  Facilities: `/facilities`,

  Services: `/services`,

  Languages: `/languages`,
  Cities: `/cities`,
  Zones: `/zones`,

  ViewDepartment: `/departments/view/:departmentId`,
  ManageDepartmentHours: `/departments/manage-hours/:departmentId`,
  Logout: `/settings/logout`,
  NotFound: `*`,
};
